.body {
  display: flex;
  flex-flow: row nowrap;
  background: #fafafa;
  align-items: center;
  justify-content: center;
  color: #000;
  width: 50%;
  min-height: 100%;
  position: absolute;
  right: 0;
  overflow: auto;
}

.body-night {
  display: flex;
  flex-flow: row nowrap;
  background: #747474;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  width: 50%;
  min-height: 100%;
  position: absolute;
  right: 0;
  overflow: auto;
}

.body-tab {
  display: flex;
  position: fixed;
  top: 0;
  width: inherit;
  right: 0;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}

.body-tab-night {
  display: flex;
  position: fixed;
  top: 0;
  width: inherit;
  right: 0;
  max-width: 100;
  align-items: center;
  justify-content: center;
  color: #000;
  background: #747474;
}

.body-Page {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

@media all and (max-width: 768px) {
  .body {
    display: flex;
    flex-flow: column nowrap;
    background: #fff;
    align-items: center;
    justify-content: center;
    color: #000;
    width: 100%;
    min-height: 50%;
    max-height: 50%;
    bottom: 0;
    overflow: auto;
  }

  .body-night {
    display: flex;
    flex-flow: column nowrap;
    background-color: #747474;
    align-items: center;
    justify-content: center;
    color: #fafafa;
    width: 100%;
    min-height: 50%;
    max-height: 50%;
    position: fixed;
    bottom: 0;
    overflow: auto;
  }

  .body-tab {
    position: fixed;
    bottom: 0;
    top: inherit;
    background: #fafafa;
    z-index: 5;

    box-shadow: 1px #f1d376;
  }

  .body-tab-night {
    position: fixed;
    bottom: 0;
    top: inherit;
    background: #02292c;
  }

 
}
