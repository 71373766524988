
@font-face {
  font-family: "3270Medium";
  src: local("3270Medium"), url(./assets/fonts/3270Medium.ttf) format("truetype");
}

@font-face {
  font-family: "3270Narrow";
  src: local("3270Narrow"), url(./assets/fonts/3270Narrow.ttf) format("truetype");
}

@font-face {
  font-family: "3270SemiNarrow";
  src: local("3270SemiNarrow"),
    url(./assets/fonts/3270SemiNarrow.ttf) format("truetype");
}

.App {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: "3270Narrow";
}

