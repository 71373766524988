.headerLight {
  background-color: #30a4a6;
  color: #fafafa;
  display: flex;
  flex-flow: column;

  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  min-height: 100%;
  position: fixed;
  left: 0;
}

.headerNight {
  background-color: #02292c;
  color: #959595;
  display: flex;
  flex-flow: column;

  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  min-height: 100%;
  position: fixed;
  left: 0;
}

.header-logo {
  height: 18vh;
  color: white;
  align-items: center;
  border-radius: 100px;
}

.header-logo-night {
  height: 18vh;
  color: white;
  align-items: center;
  border-radius: 100px;

  filter: grayscale(100%);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
}

h2 {
  font-family: "3270Narrow";
  font-size: 2em;
  letter-spacing: 0.3em; 
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1vh;
}

h5 {
  font-family: "3270Narrow";
  font-size: 1.3em;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 1vh;
}

.header-title {
  font-family: "3270Medium";
  font-size: 1rem;
  font-weight: 600;
  color: #fafafa;
  /*text-transform: uppercase;*/
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
}

.header-title-night {
  font-family: "3270Medium";
  font-size: 1.9em;
  font-weight: 600;
  color: #cfb971;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 2s steps(44) 1s 1 normal both,
    blinkTextCursorNew 500ms steps(40) infinite;
}

.header-icon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;
  align-content: space-around;
  margin-top: 1vh;
}

.header-moon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@media all and (max-width: 768px) {
  .header-logo {
    height: 10vh;
    color: white;
    align-items: center;
    border-radius: 100px;
    margin-top: 2vh;
  }

  .header-logo-night {
    height: 10vh;
    color: white;
    align-items: center;
    border-radius: 100px;

    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    margin-top: 2vh;
  }

  .headerLight {
    display: flex;
    flex-flow: column nowrap;

    background-color: #30a4a6;
    color: #fafafa;

    justify-content: center;
    align-items: center;

    width: 100%;

    min-width: 100%;
    min-height: 50%;
    max-height: 50%;

    position: fixed;
    top: 0;

    overflow: auto;
  }

  .headerNight {
    display: flex;
    flex-flow: column nowrap;

    background-color: #02292c;
    color: #959595;

    justify-content: center;
    align-items: center;
    min-width: 100%;

    width: 100%;
    min-height: 50%;
    max-height: 50%;

    position: fixed;
    top: 0;

    overflow: auto;
  }

  h2 {
    font-family: "3270Narrow";
    font-size: 4vh;
    letter-spacing: 0.15em; 
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0vh;
  }
  
  h5 {
    font-family: "3270Narrow";
    font-size: 1em;
    font-weight: 300;
    text-transform: capitalize;
    margin-bottom: 0vh;
  }

  .header-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-align: center;
    align-content: space-around;
    margin-top: 0vh;
  }

  .header-moon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 2vh;
  }
}
